import React from 'react'
import {Link, Outlet,useLocation} from 'react-router-dom';

function Product() {
    const location = useLocation();
    console.log(location);
  return (
    <>
      <h3>This is a product Page</h3>

      <h3>Product 1</h3>
      <Link to="prod1" state={{name:'product name',brand:'testing'}}>product 1</Link>
      <h3>Product 2</h3>
      <Link to="prod2">product 2</Link>
      <h3>Product 3</h3>
      <Link to="prod3">product 3</Link>
      <h3>Product 4</h3>
      <Link to="prod4">product 4</Link>
      <Outlet/>
    </>
  )
}

export default Product
