import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function Home() {
  const location = useLocation();
  console.log(location);
  return (
    <>
      Home Page
      <p>this is a home page of our app...</p>
      <p>and here we are learning...</p>
      <Link to="/about">Go to About Page</Link>
      
    </>
  )
}

export default Home