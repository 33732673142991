import React from 'react'

function Product_4() {
  return (
    <>
      <h3>This is a product four page.</h3>
    </>
  )
}

export default Product_4