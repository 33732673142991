import React from 'react'

import {Link,useLocation} from 'react-router-dom'

function Navbar() {
  const location = useLocation();
  console.log(location);
  return (
    <>
      <Link to='/about'>About</Link>
        <br/>
      <Link to='/'>Home</Link>
      <br/>
      <Link to='/contact'>Contact</Link>
      <br/>
      <Link to='/product'>Product</Link>
    </>
  )
}

export default Navbar
