import React from 'react'
import { Link } from 'react-router-dom'

function About() {
  return (
    <>
      About Page
      <p>this is a about page of our app...</p>
      <p>and here we are learning...</p>
      <Link to="/">go to home page</Link>
    </>
  )
}

export default About
