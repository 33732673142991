import React from 'react'
import { Link , useNavigate } from 'react-router-dom'

function Page404() {
  const navigate = useNavigate();
  const navToPage =(url)=> {
    navigate(url);
  }
  return (
    <>
      <h3>page not found...</h3>
      <Link to="/">Back to Home</Link> <br/>
      <button onClick={()=> navToPage('/about')} >About Us</button>
      <button onClick={()=> navToPage('/')}>Home</button>
    </>
  )
}

export default Page404
