
import './App.css';
import {BrowserRouter, Routes,Route, Navigate} from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Navbar from './components/Navbar';
import Page404 from './components/Page404';
import Product from './components/Product';
import Product_1 from './components/products/Product_1';
import Product_2 from './components/products/Product_2';
import Product_3 from './components/products/Product_3';
import Product_4 from './components/products/Product_4';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/product/" element={<Product/>}>
            <Route path="prod1" element={<Product_1/>} />
            <Route path="prod2" element={<Product_2/>} />
            <Route path="prod3" element={<Product_3/>} />
            <Route path="prod4" element={<Product_4/>} />
          </Route>
          <Route path="/*" element={<Page404/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
