import React from 'react'

function Product_2() {
  return (
    <>
      <h3>This is a product two page.</h3>
    </>
  )
}

export default Product_2