import React from 'react'
import { useLocation } from 'react-router-dom';
function Product_3() {
  const location = useLocation();
  console.log(location);
  return (
    <>
      <h3>This is a product three page.</h3>
    </>
  )
}

export default Product_3