import React from 'react'
import { useLocation } from 'react-router-dom'

function Product_1() {
    const location = useLocation();
  return (
    <>
      <h3>This is a product one page.</h3>
      <h4>{location.state.brand}</h4>
      <h4>{location.state.name}</h4>
    </>
  )
}

export default Product_1